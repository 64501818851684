@import "./styles/colors";
$body-bg: #32303a;
$modal-content-bg: $main-color;
$modal-content-color: #fff;
$modal-content-border-width: 0px;
$close-color: #fff;

$dark: #3a3848;
$body-color: #fff;
$input-bg: #32303a;
$input-color: #fff;
$input-placeholder-color: #fff;

$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-base: 0.875rem;

$btn-border-radius: 10px;
$btn-font-weight: bold;

$input-border-width: 0px;
$input-height: 40px;
$navbar-toggler-border-radius: 0px;

$navbar-toggler-padding-y: 0.75rem;
$navbar-toggler-padding-x: 0.75rem;

$dropdown-bg: #2a2931;
$dropdown-color: #fff;
$dropdown-link-color: #fff;
$dropdown-link-hover-bg: #3a3848;
$dropdown-link-hover-color: #fff;

$table-dark-bg: #32303a;
$table-dark-border-color: transparent;
$table-dark-accent-bg: #3a3848;
$navbar-dark-active-color: #2d7aed;

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
	margin: 0;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.login {
	@media (max-device-width: 480px) {
		background: #3a3848 0% 0% no-repeat padding-box;
	}
}

.form-control[type="text"],
.form-control[type="password"],
.form-control[type="number"],
.form-control[type="email"],
.form-control[type="url"],
.form-control[type="date"],
select.form-control {
	border-radius: 10px;
}

label {
	font-weight: bold;
}

input[type="checkbox"].css-checkbox {
	position: absolute;
	z-index: -1000;
	left: -1000px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

input[type="checkbox"].css-checkbox + label.css-label {
	padding-left: 26px;
	height: 20px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 0 0;
	font-size: 12px;
	line-height: 16px;
	vertical-align: middle;
	width: auto;
	cursor: pointer;
}

input[type="checkbox"].css-checkbox:checked + label.css-label {
	background-position: 0 -20px;
}
label.css-label {
	background-image: url(./assets/checkbox.png);
	width: auto;
	line-height: 16px !important;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.navbar {
	box-shadow: 0px 5px 10px #0000001a;
}

.navbar-toggler {
	background: #312f39 0% 0% no-repeat padding-box;
	border: none;
	border-radius: 10px;
}

.navbar-nav .nav-link {
	font-weight: bold;
	font-size: 16px;
}

.navbar-dark {
	padding-bottom: 0;
}

@include media-breakpoint-up(lg) {
	.navbar-dark .navbar-nav .nav-link {
		border-bottom: 6px solid transparent;
		padding: 10px;
		padding-bottom: 20px;
		margin-left: 30px;
	}
	.navbar-dark .navbar-nav .nav-link.active {
		border-bottom-color: #2d7aed;
	}
}
.dropdown-toggle-rm-icon::after {
	display: none;
}

.ThUmBX {
	background: #6f6f6f !important;
	height: 17px !important;
	border-radius: 5px !important;
}

.TrackX {
	height: 17px !important;
}

// .trackY {
// }

.tHuMbY {
	background: #6f6f6f !important;
	border-radius: 5px !important;
}

.blink_me {
	animation: blinker 2s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.fg-white {
	color: white;
}

.table {
	border-radius: 10px;
}

@include media-breakpoint-down(sm) {
	.plant-container {
		margin-top: 20px;
	}
	.plant-container:first-of-type {
		margin-top: 0px;
	}
}

.btn-link {
	font-weight: bold;
	color: rgba(255, 255, 255, 0.5);
	border-radius: 0;
	&:hover {
		color: white;
		text-decoration: none;
	}
	&.active {
		color: white;
		text-decoration: none;
		border-bottom: 3px solid #2d7aed;
	}
}

.fg-black {
	color: #000;
}

.btn-show-password {
	height: 40px;
}
.main-scroll {
	height: calc(100vh - 67.5px);
	height: calc(100 * var(--vh) - 67.5px);
}
@supports (-webkit-touch-callout: none) {
	.touchScroll {
		-webkit-overflow-scrolling: touch;
	}
}

.custom-nav {
	padding-top: env(safe-area-inset-top, 0px);
}
